import * as React from "react";
import { Brand } from "./brand";

export const themeContext = React.createContext<{
  viewMode?: Brand;
  setViewMode: React.Dispatch<React.SetStateAction<Brand>>;
}>({
  viewMode: undefined,
  setViewMode: () => {},
});
